import { AxiosResponse } from 'axios'
import { InviteUser, UpdateUser, User, UsersType } from 'types/user'

import { axiosFuncAppInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  user(authId: string): Promise<AxiosResponse<User, any>> {
    return axiosFuncAppInstance.get(`/users/authid/${authId}`)
  },

  usersBy(params: {
    firstName: string | undefined
    lastName: string | undefined
    email: string | undefined
  }): Promise<AxiosResponse<User[], any>> {
    return axiosFuncAppInstance.get(
      // eslint-disable-next-line prettier/prettier
      `/user?firstName=${params.firstName ? params.firstName : null}&lastName=${
        params.lastName ? params.lastName : null
      }&email=${params.email ? params.email : null}`
    )
  },

  users(): Promise<AxiosResponse<UsersType[], any>> {
    return axiosFuncAppInstance.get('/users')
  },
}

const saves = {
  inviteUser(params: { user: InviteUser }): Promise<AxiosResponse<User, any>> {
    return axiosFuncAppInstance.post('/user/send-invitation', params.user)
  },

  disableEnableUser(params: {
    UserId: string[]
    IsActive: boolean
  }): Promise<AxiosResponse<User, any>> {
    return axiosFuncAppInstance.put('/user/enable', params)
  },

  updateUser(user: UpdateUser): Promise<AxiosResponse<User, any>> {
    return axiosFuncAppInstance.put('/update/user-profile', user)
  },
}

const deletes = {
  user(params: { id: string[] }): Promise<AxiosResponse<User, any>> {
    return axiosFuncAppInstance.delete(`/user/${params.id}`)
  },
}

export default class UserService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
