import axios from 'axios'
import User from 'services/user'
import { logout } from 'stores/auth'
import RoleService from 'services/roles'
import AccountService from 'services/account'
import ReportService from 'services/report'

// Axios init start -----------------
const axiosFuncAppInstance = axios.create({
  baseURL: process.env.REACT_APP_FUNC_API_BASE_URL,
})

const axiosDataverseAppInstance = axios.create({
  baseURL: process.env.REACT_APP_DATAVERSE_API_BASE_URL,
})

const axiosPortalAppInstance = axios.create({
  baseURL: process.env.REACT_APP_FUNC_PORTAL_API_BASE_URL,
})

axiosFuncAppInstance.interceptors.request.use(
  request => {
    const originalRequest = request
    const accessToken = localStorage.getItem('accessToken')
    const subKey = process.env.REACT_APP_SUBSCRIPTION_KEY
    if (accessToken) {
      //Update accessToken to axios headers
      if (originalRequest.headers) {
        originalRequest.headers['Authorization'] = accessToken
          ? `Bearer ${JSON.parse(accessToken)}`
          : accessToken
      }
    }
    if (subKey) {
      //Update subscription key to axios headers
      if (originalRequest.headers) {
        originalRequest.headers['Ocp-Apim-Subscription-Key'] = subKey
      }
    }
    // else {
    //   logout()
    // }
    return originalRequest
  },
  error => {
    return Promise.reject(error)
  }
)

axiosDataverseAppInstance.interceptors.request.use(
  request => {
    const originalRequest = request
    const accessToken = localStorage.getItem('accessToken')
    const subKey = process.env.REACT_APP_SUBSCRIPTION_KEY
    if (accessToken) {
      //Update accessToken to axios headers
      if (originalRequest.headers) {
        originalRequest.headers['Authorization'] = accessToken
          ? `Bearer ${JSON.parse(accessToken)}`
          : accessToken
      }
    }
    if (subKey) {
      //Update subscription key to axios headers
      if (originalRequest.headers) {
        originalRequest.headers['Ocp-Apim-Subscription-Key'] = subKey
      }
    }
    // else {
    //   logout()
    // }
    return originalRequest
  },
  error => {
    return Promise.reject(error)
  }
)

axiosPortalAppInstance.interceptors.request.use(
  request => {
    const originalRequest = request
    const accessToken = localStorage.getItem('accessToken')
    const subKey = process.env.REACT_APP_SUBSCRIPTION_KEY
    if (accessToken) {
      //Update accessToken to axios headers
      if (originalRequest.headers) {
        originalRequest.headers['Authorization'] = accessToken
          ? `Bearer ${JSON.parse(accessToken)}`
          : accessToken
      }
    }
    if (subKey) {
      //Update subscription key to axios headers
      if (originalRequest.headers) {
        originalRequest.headers['Ocp-Apim-Subscription-Key'] = subKey
      }
    }
    return originalRequest
  },
  error => {
    return Promise.reject(error)
  }
)

axiosFuncAppInstance.interceptors.response.use(
  response => response,
  error => {
    // GA-Track exception
    // exceptionEvent(error)
    if (error.response.status === 401) {
      logout()
      return Promise.reject(error)
    }

    if (error.response.status === 403) {
      // logout()
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

axiosDataverseAppInstance.interceptors.response.use(
  response => response,
  error => {
    // GA-Track exception
    // exceptionEvent(error)
    if (error.response.status === 401) {
      logout()
      return Promise.reject(error)
    }

    if (error.response.status === 403) {
      // logout()
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

axiosPortalAppInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      logout()
      return Promise.reject(error)
    }

    if (error.response.status === 403) {
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export {
  axiosFuncAppInstance,
  axiosDataverseAppInstance,
  axiosPortalAppInstance,
}

// Axios init end -----------------

export default {
  User: new User(),
  Role: new RoleService(),
  Account: new AccountService(),
  Report: new ReportService(),
}
