import { AxiosResponse } from 'axios'
import { ReportsCollection } from 'types/user'

import { axiosPortalAppInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  cosmosReportsList(): Promise<AxiosResponse<ReportsCollection[], any>> {
    return axiosPortalAppInstance.get('/reportcollections')
  },
}

const saves = {}

export default class ReportService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }
}
