import { AxiosResponse } from 'axios'

import { axiosFuncAppInstance, axiosDataverseAppInstance } from '.'
import AppService from './appService'
import { BillingAccount, SiteType } from 'types/user'

const values = {}

const getters = {
  billingAccountsList(): Promise<AxiosResponse<BillingAccount[], any>> {
    return axiosFuncAppInstance.get('/billing-accounts')
  },

  sitesByBillingAccountIds(params: {
    billingAccountIds: string[]
  }): Promise<AxiosResponse<SiteType[], any>> {
    return axiosDataverseAppInstance.post(
      `/activesites`,
      params.billingAccountIds
    )
  },
}

const saves = {}

const deletes = {}

export default class AccountService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
