import { Autocomplete, TextField } from '@mui/material'
import { Controller, useWatch } from 'react-hook-form'
import type { Control } from 'react-hook-form'
import { AutocompleteItem } from 'types/FormFields'
import { useState, useEffect } from 'react'
import { isEqual } from 'lodash'

interface UserInviteAutocompleteProps {
  control: Control<any>
  options: AutocompleteItem[]
  label: string
  fieldName: string
  filterSelectedOptions?: boolean
  fullWidth?: boolean
  size?: 'small' | 'medium'
  loading?: boolean
  limitTags?: number
}

export default function UserInviteAutocomplete({
  control,
  options,
  label,
  fieldName,
  filterSelectedOptions = false,
  fullWidth = false,
  size = 'small',
  loading = false,
  limitTags = 3,
}: UserInviteAutocompleteProps): JSX.Element {
  const [inputValue, setInputValue] = useState('')

  // Watch the value of the field from the control
  const watchedValue = useWatch({
    control,
    name: fieldName,
  })

  useEffect(() => {
    setValue(watchedValue || [])
  }, [watchedValue])

  const [value, setValue] = useState<AutocompleteItem[]>(watchedValue || [])

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={[]}
      render={({ field }): JSX.Element => (
        <Autocomplete
          {...field}
          value={value}
          fullWidth={fullWidth}
          size={size}
          limitTags={limitTags}
          options={options as AutocompleteItem[]}
          filterSelectedOptions={filterSelectedOptions}
          isOptionEqualToValue={(
            option: AutocompleteItem,
            value: AutocompleteItem
          ): boolean => isEqual(option.id, value.id)}
          renderOption={(props, option): JSX.Element => {
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )
          }}
          renderInput={(params): JSX.Element => (
            <TextField {...params} label={label} />
          )}
          onChange={(event, newValue): void => {
            setValue(newValue as AutocompleteItem[])
            field.onChange(newValue)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue): void => {
            setInputValue(newInputValue)
          }}
          loading={loading}
          multiple
          disableCloseOnSelect
        />
      )}
    />
  )
}
