import { atom, useAtom } from 'jotai'
import jwt_decode from 'jwt-decode'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import AppService from 'services'
import { InviteUser, User, UserRole, ReportsCollection } from 'types/user'
import { AxiosResponse } from 'axios'

export const isUserLoadingAtom = atom<boolean>(false)

const storage = createJSONStorage(() => sessionStorage)
export const userAtom = atomWithStorage<User | any>('user', null, storage)

interface accessTokenType {
  iss: string
  sub: string
  aud: string[]
  iat: number
  exp: number
  azp: string
  scope: string
  permissions: string[]
}

export const idTokenAtom = atomWithStorage<string | any>('idToken', null)

export const accessTokenAtom = atomWithStorage<string | any>(
  'accessToken',
  null
)

export const getDecodedAccessToken = (): accessTokenType | any => {
  const [accessToken] = useAtom(accessTokenAtom)
  if (accessToken) {
    return jwt_decode(accessToken)
  } else {
    // eslint-disable-next-line no-console
    console.warn('Invalid access token!')
    return undefined
  }
}

export const isDrawerOpen = atom(false)

export const logout = (): void => {
  window.sessionStorage.clear()
  window.localStorage.clear()
}

// User
export const trackLogin = async (): Promise<AxiosResponse<User, any>> => {
  return await AppService.User.saveData('trackLogin', null)
}

export const getUser = async (
  authId: string
): Promise<AxiosResponse<User, any>> => {
  return await AppService.User.getData('user', authId, true)
}

export const inviteUser = async (
  user: InviteUser
): Promise<AxiosResponse<User, any>> => {
  try {
    return await AppService.User.saveData('inviteUser', { user })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getUserRolesList = async (): Promise<
  AxiosResponse<UserRole[], any>
> => {
  try {
    return await AppService.Role.getData('userRolesList', null, true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getCosmosReportsList = async (): Promise<
  AxiosResponse<ReportsCollection[], any>
> => {
  try {
    return await AppService.Report.getData('cosmosReportsList', null, true)
  } catch (error) {
    return Promise.reject(error)
  }
}
