interface RegExps {
  [key: string]: RegExp
}

const regex: RegExps = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  postalCode: /(^(?!0{5})(\d{5})(?!-?0{4})(|-\d{4})?$)/,
  houseNumber: /^[1-9]\d*(?:[-\s]?[a-zA-Z0-9]+)?$/,
  address: /^[a-zA-Z0-9\s,'-.]*$/,
  city: /^(?:[A-Za-z]{2,}(?:(\.\s|'s\s|\s?-\s?|\s)?(?=[A-Za-z]+))){1,2}(?:[A-Za-z]+)?$/,
  name: /^[^<>]{2,256}$/,
  uuid: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
}

export const getRegex = (key: string): RegExp => {
  let expression = /.*/ //Match any

  if (regex[key]) {
    expression = regex[key]
  }

  return expression
}
