import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import { useAtom } from 'jotai'
import { useEffect } from 'react'

import { accessTokenAtom, logout } from 'stores/auth'
import { useMsal } from '@azure/msal-react'
import Loading from 'components/molecules/Loading'

const Logout = (): JSX.Element => {
  const [accessToken] = useAtom(accessTokenAtom)
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  useEffect(() => {
    if (accessToken && activeAccount) {
      // instance.logoutRedirect()
      logout()
    }
  }, [accessToken])

  return (
    <AdminPageWrapper pageTitle="Logout">
      <Loading height="400px" message="Loading Users..." />
    </AdminPageWrapper>
  )
}

export default Logout
