import { styled } from '@mui/system'
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Checkbox,
  Chip,
  CircularProgress,
  TextField,
  Typography,
  darken,
  lighten,
} from '@mui/material'

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'

import MuiAccordionDetails from '@mui/material/AccordionDetails'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { AutocompleteGroupItem, InviteUserFormFields } from 'types/FormFields'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Fragment, SyntheticEvent, useEffect, useState } from 'react'
import { map, uniq, difference } from 'lodash'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: 38,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
    alignItems: 'center',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function AutoCompleteCategory({
  control,
  options,
  label,
  multiple = false,
  fieldName,
  fullWidth = false,
  size = 'small',
  loading = false,
  limitTags = 3,
  selectedValues = [],
  setValue,
}: {
  control: Control<any>
  options: AutocompleteGroupItem[]
  label: string
  multiple?: boolean
  fieldName: string
  fullWidth?: boolean
  size?: 'small' | 'medium'
  loading?: boolean
  limitTags?: number
  selectedValues?: AutocompleteGroupItem[]
  setValue: UseFormSetValue<InviteUserFormFields>
}): JSX.Element {
  const [expanded, setExpanded] = useState<string | false>(false)
  const [selectedSites, setSelectedSites] = useState<AutocompleteGroupItem[]>(
    []
  )

  // const watchedValue = useWatch({
  //   control,
  //   name: fieldName,
  // })

  useEffect(() => {
    if (selectedValues.length) {
      setSelectedSites(selectedValues)
    }
  }, [selectedValues])

  useEffect(() => {
    if (!loading) {
      if (!options.length) {
        setSelectedSites([])
        setValue('sites', [])
      } else {
        const newOptionGroupIds = options.map(option => option.groupById)
        const updatedSites = selectedSites?.filter(site =>
          newOptionGroupIds.includes(site.groupById)
        )
        setSelectedSites(updatedSites)
        setValue('sites', updatedSites)
      }
    }
  }, [options, loading])

  const handleChange =
    (panel: string) =>
    (event: SyntheticEvent, newExpanded: boolean): void => {
      if (!(event.target instanceof HTMLInputElement)) {
        setExpanded(newExpanded ? panel : false)
      }
    }

  const getUniqueGroups = (options: AutocompleteGroupItem[]): string[] => {
    return uniq(map(options, 'groupBy'))
  }

  const isOptionChecked = (option: AutocompleteGroupItem): boolean => {
    if (selectedSites.length) {
      if (getSelectedSitesGroupByLength(option.groupBy)) {
        return selectedSites.some(site => site.id === option.id)
      }
    }
    // No selected sites or no selected sites byGroup then select everything
    return true
  }

  const getSitesByGroupLength = (group: string): number => {
    return options?.filter(opt => opt.groupBy === group).length
  }

  const getSelectedSitesGroupByLength = (group: string): number => {
    return selectedSites?.filter(c => c.groupBy === group).length
  }

  const isGroupChecked = (params: AutocompleteRenderGroupParams): boolean => {
    // if there are no selections then consider everything is checked by default

    return (
      getSelectedSitesGroupByLength(params.group) === 0 ||
      getSelectedSitesGroupByLength(params.group) ===
        getSitesByGroupLength(params.group)
    )
  }

  const isGroupIndeterminate = (
    params: AutocompleteRenderGroupParams
  ): boolean => {
    return (
      getSelectedSitesGroupByLength(params.group) > 0 &&
      getSitesByGroupLength(params.group) !==
        getSelectedSitesGroupByLength(params.group)
    )
  }

  const selectGroup = (params: AutocompleteRenderGroupParams): void => {
    if (selectedSites.length) {
      setSelectedSites(prevState => {
        const updatedSites = prevState?.filter(
          site => site.groupBy !== params.group
        )
        setValue('sites', updatedSites, {
          shouldValidate: true,
          shouldDirty: true,
        })
        return [...updatedSites]
      })
    }
  }

  return (
    <Controller
      rules={{ required: false }}
      render={({ field }): JSX.Element => (
        <Autocomplete
          {...field}
          value={selectedSites}
          inputValue={''}
          limitTags={limitTags}
          options={options.sort((a, b) => -b.groupBy.localeCompare(a.groupBy))}
          groupBy={(option): string => option.groupBy}
          getOptionLabel={(option): string => option.label}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              label={label}
              helperText="By default, all sites are selected until any specific site is chosen."
              FormHelperTextProps={{ sx: { fontWeight: 'normal' } }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <div
                    style={{
                      maxHeight: 288,
                      overflowY: 'auto',
                    }}
                  >
                    {params.InputProps.startAdornment}
                  </div>
                ),

                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          renderGroup={(params): JSX.Element => (
            <>
              <Accordion
                key={params.key}
                expanded={expanded === params.group}
                onChange={handleChange(params.group)}
              >
                <AccordionSummary id={params.group + '-header'}>
                  <Checkbox
                    className="select-all-sites"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    sx={{ mr: 0.5 }}
                    checked={isGroupChecked(params)}
                    indeterminate={isGroupIndeterminate(params)}
                    onChange={(): void => selectGroup(params)}
                  />
                  <Typography sx={{ display: 'flex', flexGrow: 1 }}>
                    {params.group}
                  </Typography>
                  <Typography color="primary" sx={{ mr: 2 }} variant="body2">
                    {getSitesByGroupLength(params.group)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {getSelectedSitesGroupByLength(params.group) === 0 ? (
                    <Typography
                      sx={{
                        px: 4,
                        py: 0.8,
                        fontWeight: 'thin',
                        textTransform: 'uppercase',
                        fontSize: '0.7rem',
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                      }}
                    >
                      All sites are auto selected unless you choose specific
                      ones below.
                    </Typography>
                  ) : null}
                  <GroupItems>{params.children}</GroupItems>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          renderOption={(props, option): JSX.Element => (
            <li {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                sx={{ mr: 0.5, ml: 2 }}
                checked={isOptionChecked(option)}
              />
              {option.label}
            </li>
          )}
          renderTags={(): any => {
            const sitesSelected: any = []
            const goupsWithAllSitesSelectd: string[] = selectedSites
              ? difference(
                  getUniqueGroups(options),
                  getUniqueGroups(selectedSites)
                )
              : getUniqueGroups(options)

            goupsWithAllSitesSelectd.map(group => {
              sitesSelected.push(
                <Chip
                  label={`All Sites::${group}`}
                  key={group}
                  sx={{ m: 0.25 }}
                />
              )
            })

            selectedSites.map(site => {
              sitesSelected.push(
                <Chip
                  label={site.label}
                  key={site.id}
                  onDelete={(): void => {
                    setSelectedSites(prevState => {
                      const updatedSites = prevState?.filter(
                        item => item.id !== site.id
                      )
                      setValue('sites', updatedSites, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                      return updatedSites
                    })
                  }}
                  sx={{ m: 0.25 }}
                />
              )
            })

            return sitesSelected
          }}
          onChange={(event, option): void => {
            const updatedSites = [...(option as AutocompleteGroupItem[])]
            setSelectedSites(updatedSites)
            field.onChange(updatedSites)
            setValue('sites', updatedSites, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }}
          size={size}
          loading={loading}
          fullWidth={fullWidth}
          multiple={multiple}
          disableCloseOnSelect={multiple}
        />
      )}
      defaultValue={null}
      name={fieldName}
      control={control}
    />
  )
}
