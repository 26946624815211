import { useMsal } from '@azure/msal-react'
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import logo from 'assets/img/RTS_logo.png'
import { loginRequest } from 'authConfig'
import { Navigate } from 'react-router-dom'

const Home = (): JSX.Element => {
  const theme = useTheme()
  const { instance } = useMsal()
  let activeAccount

  if (instance) {
    activeAccount = instance.getActiveAccount()
  }
  if (activeAccount) {
    return <Navigate to="/users" state={{ returnTo: location }} />
  }

  const handleLogin = (): void => {
    instance.loginRedirect(loginRequest).catch(error => {
      console.error(error)
    })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        component="nav"
        position="fixed"
        elevation={0}
        sx={{ backgroundColor: theme.palette.primary.dark }}
      >
        <Toolbar sx={{ minHeight: '3rem!important' }}>
          <Box sx={{ display: 'flex', alignItems: 'end', flexGrow: 1 }}>
            <img src={logo} style={{ height: '1.875rem' }} />
            <Typography
              data-testid="app-title"
              variant="small"
              component="div"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 100,
                fontSize: '0.75rem',
                ml: 1,
              }}
            >
              User Management
            </Typography>
          </Box>
          <Box>
            <Button
              sx={{ color: '#fff' }}
              onClick={handleLogin}
              data-testid="login"
            >
              login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar sx={{ minHeight: '3rem!important' }} />
        <Typography>User Management</Typography>
      </Box>
    </Box>
  )
}
export default Home
