import { useState, useEffect, ReactNode } from 'react'

import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

import { loginRequest } from 'authConfig'
import {
  checkRolesAndPermissionsInStorage,
  getPermissionsFromStorage,
  getRolesFromStorage,
  setRolesAndPermissionsInStorage,
} from 'utils/storageUtils'

interface AuthenticationGuardProps {
  component: ReactNode
  requiredPermissions?: string[]
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  component,
  requiredPermissions = [],
}: AuthenticationGuardProps): JSX.Element => {
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()
  const [isAuthorized, setIsAuthorized] = useState(false)

  const onLoad = (): void => {
    // check either the ID token or a non-expired storage entry for the required claims
    if (
      (!activeAccount ||
        !activeAccount?.idTokenClaims?.extension_AppUserRole ||
        !activeAccount?.idTokenClaims?.extension_RolePermissions) &&
      !checkRolesAndPermissionsInStorage(activeAccount)
    ) {
      return
    }

    const permissionsString: string | undefined =
      typeof activeAccount?.idTokenClaims?.extension_RolePermissions ===
      'string'
        ? (activeAccount.idTokenClaims.extension_RolePermissions as string)
        : undefined
    const permissionsArray =
      permissionsString?.split(',') || getPermissionsFromStorage(activeAccount)

    const hasRequiredPermission = requiredPermissions.every(permission =>
      permissionsArray.includes(permission)
    )
    setIsAuthorized(hasRequiredPermission)

    if (hasRequiredPermission) {
      const rolesString: string | undefined =
        typeof activeAccount?.idTokenClaims?.extension_AppUserRole === 'string'
          ? (activeAccount.idTokenClaims.extension_AppUserRole as string)
          : undefined
      const rolesArray =
        rolesString?.split(',') || getRolesFromStorage(activeAccount)
      setRolesAndPermissionsInStorage(
        activeAccount,
        rolesArray,
        permissionsArray
      )
    }
  }

  useEffect(() => {
    onLoad()
  }, [activeAccount])

  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      {isAuthorized ? (
        <div>{component}</div>
      ) : (
        <div className="data-area-div">
          <h1>Access Denied</h1>
          <h3>You are unauthorized to view this content.</h3>
        </div>
      )}
    </MsalAuthenticationTemplate>
  )
}
