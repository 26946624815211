// import { useAuth0 } from '@auth0/auth0-react'
// import { Avatar, Box, Card, CardContent, Chip, Typography } from '@mui/material'
// import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import Loading from 'components/molecules/Loading'
// import { getDecodedAccessToken } from 'stores/auth'

const Profile = (): JSX.Element => {
  // const { user } = useAuth0()
  // const access = getDecodedAccessToken()
  // const audience = process.env.REACT_APP_AUTH0_AUDIENCE

  // return user ? (
  //   <AdminPageWrapper pageTitle="Profile">
  //     <Box>
  //       <Card
  //         sx={{
  //           minWidth: 275,
  //           position: 'relative',
  //           overflow: 'visible',
  //           msOverflowX: 'hidden',
  //           mt: '6.25rem',
  //         }}
  //       >
  //         <CardContent
  //           sx={{
  //             display: 'flex',
  //             flexDirection: 'column',
  //             alignItems: 'center',
  //           }}
  //         >
  //           {user ? (
  //             <>
  //               <Avatar
  //                 alt={user.name}
  //                 src={user.picture}
  //                 sx={{
  //                   width: 100,
  //                   height: 100,
  //                   position: 'absolute',
  //                   top: '-3.125rem',
  //                 }}
  //               />

  //               <Typography
  //                 variant="h3"
  //                 sx={{ mt: 8 }}
  //                 color="text.primary"
  //                 data-testid="user-name"
  //               >
  //                 {user.name}
  //               </Typography>
  //               <Typography
  //                 variant="small"
  //                 sx={{ fontSize: '1.2rem', fontWeight: '100', mt: '0.3rem' }}
  //                 color="GrayText"
  //                 data-testid="user-email"
  //               >
  //                 {user.email}
  //               </Typography>
  //               <Typography
  //                 variant="small"
  //                 sx={{ fontSize: '1rem', fontWeight: '100', mt: '0.3rem' }}
  //                 color="GrayText"
  //                 data-testid="user-id"
  //               >
  //                 User Id: {user.sub?.split('|')[1]}
  //               </Typography>
  //             </>
  //           ) : null}
  //           {access ? (
  //             <Typography
  //               variant="small"
  //               sx={{
  //                 fontSize: '1rem',
  //                 fontWeight: '100',
  //                 mt: '1rem',
  //                 display: 'flex',
  //                 alignItems: 'center',
  //               }}
  //               color="GrayText"
  //               component="div"
  //               data-testid="user-roles-permissions"
  //             >
  //               Roles:
  //               <Typography
  //                 variant="overline"
  //                 component="span"
  //                 sx={{ lineHeight: '1rem', mx: 1 }}
  //                 data-testid="user-roles"
  //               >
  //                 {access[`${audience}//roles`] || [].join(', ')}
  //               </Typography>
  //               {access.permissions && access.permissions.length ? (
  //                 access.permissions.map((item: string) => {
  //                   return (
  //                     <Chip
  //                       size="small"
  //                       key={item}
  //                       color="secondary"
  //                       label={item}
  //                       sx={{ mx: 0.3 }}
  //                       data-testid="user-permission"
  //                     />
  //                   )
  //                 })
  //               ) : (
  //                 <Chip size="small" color="error" label="None" />
  //               )}
  //             </Typography>
  //           ) : null}
  //         </CardContent>
  //       </Card>
  //     </Box>
  //   </AdminPageWrapper>
  // ) : (
  return <Loading />
  // )
}

export default Profile
