import {
  Box,
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import Icon from '@mui/material/Icon'
import { ReactNode } from 'react'
import theme from 'theme'

const ConfirmationDialog = ({
  maxWidth = 'xs',
  open,
  description,
  icon,
  iconSize = 60,
  iconColor = theme.palette.warning.main,
  action,
}: {
  maxWidth?: false | Breakpoint | undefined
  open: boolean
  description: string
  icon: string
  iconSize?: number
  iconColor?: string
  action?: ReactNode
}): JSX.Element => {
  return (
    <Dialog maxWidth={maxWidth} open={open}>
      <DialogContent sx={{ pt: 5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Icon
            sx={{
              fontSize: `${iconSize}px`,
              mb: 3,
              color: iconColor,
            }}
          >
            {icon}
          </Icon>
        </Box>
        <Typography sx={{ textAlign: 'center' }}>{description}</Typography>
      </DialogContent>
      {action ? (
        <DialogActions sx={{ justifyContent: 'center', pb: 4 }}>
          {action}
        </DialogActions>
      ) : null}
    </Dialog>
  )
}

export default ConfirmationDialog
