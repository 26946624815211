import { IDateFilterParams } from 'ag-grid-community'
import { format, parseISO } from 'date-fns'
import { RefObject } from 'react'

// AG-filter helpers
export const dateFilterParams: IDateFilterParams | any = {
  debounceMs: 500,
  maxNumConditions: true,
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    // Date is coming as a date object string from API
    const dateAsString = format(parseISO(cellValue), 'MM/dd/yyyy')

    if (dateAsString === null) {
      return 0
    }
    const dateParts = dateAsString.split('/')
    const month = Number(dateParts[0]) - 1
    const day = Number(dateParts[1])
    const year = Number(dateParts[2])
    const cellDate = new Date(year, month, day)

    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
    return 0
  },
}

export const clearFilters = (gridRef: RefObject<any>): void => {
  gridRef.current?.api.setFilterModel(null)
}

export const saveFilterModel = (gridRef: RefObject<any>): any => {
  return gridRef.current?.api.getFilterModel()
}

export const restoreFilterModel = (
  gridRef: RefObject<any>,
  savedFilterModel: any
): void => {
  gridRef.current?.api.setFilterModel(savedFilterModel)
}

export const destroyFilter = (gridRef: RefObject<any>): void => {
  gridRef.current?.api.destroyFilter('athlete')
}
