import { AxiosResponse } from 'axios'
import { UserRole } from 'types/user'

import { axiosFuncAppInstance } from '.'
import AppService from './appService'

const values = {}

const getters = {
  userRolesList(): Promise<AxiosResponse<UserRole[], any>> {
    return axiosFuncAppInstance.get('/roles')
  },
}

const saves = {}

const deletes = {}

export default class RoleService extends AppService {
  get values(): any {
    return values
  }

  get getters(): any {
    return getters
  }

  get saves(): any {
    return saves
  }

  get deletes(): any {
    return deletes
  }
}
