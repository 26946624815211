import { AxiosResponse } from 'axios'
import AppService from 'services'
import { BillingAccount, SiteType } from 'types/user'

export const getBillingAccountList = async (): Promise<
  AxiosResponse<BillingAccount[], any>
> => {
  try {
    return await AppService.Account.getData('billingAccountsList', null, true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getSitesByBillingAccountIds = async (
  billingAccountIds: string[]
): Promise<AxiosResponse<SiteType[], any>> => {
  try {
    return await AppService.Account.getData(
      'sitesByBillingAccountIds',
      { billingAccountIds },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
