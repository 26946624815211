import { AxiosResponse } from 'axios'
import AppService from 'services'
import { UpdateUser, User, UsersType } from 'types/user'

// Users
export const getUsersBy = async (
  firstName: string | undefined,
  lastName: string | undefined,
  email: string | undefined
): Promise<AxiosResponse<User[], any>> => {
  try {
    return await AppService.User.getData(
      'allUsers',
      { firstName, lastName, email },
      true
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getUsers = async (): Promise<AxiosResponse<UsersType[], any>> => {
  try {
    return await AppService.User.getData('users', {}, true)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteUser = async (
  id: string
): Promise<AxiosResponse<any, any>> => {
  try {
    return await AppService.User.deleteData('user', { id })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const disableEnableUser = async (
  userId: string,
  isActive: boolean
): Promise<AxiosResponse<User>> => {
  try {
    return await AppService.User.saveData('disableEnableUser', {
      UserIds: [userId],
      IsActive: isActive,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateUser = async (
  user: UpdateUser
): Promise<AxiosResponse<User>> => {
  try {
    return await AppService.User.saveData('updateUser', {
      ...user,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}
