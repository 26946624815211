import { atom } from 'jotai'

export interface AlertProp {
  show: boolean
  message: string
  type: 'error' | 'success' | 'info' | 'warning'
  autoHideDuration?: number | null
}

export const alertAtom = atom<AlertProp | null>(null)
