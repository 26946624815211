import { Container, Typography } from '@mui/material'

const NotFound = (): JSX.Element => {
  return (
    <Container
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#F5F7FA',
      }}
      maxWidth={false}
    >
      <Typography>404</Typography>
    </Container>
  )
}
export default NotFound
