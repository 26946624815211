import { CACHE_TTL_IN_MS } from 'authConfig'

/**
 * Stores the roles and permissions in session storage for the given account
 * @param {AccountInfo} account
 * @param {Array} roles
 * @param {Array} permissions
 */
export const setRolesAndPermissionsInStorage = (
  account,
  roles,
  permissions
) => {
  const newEntry = {
    roles: roles,
    permissions: permissions,
    lastAccessed: Date.now(),
    expiresOn: Date.now() + CACHE_TTL_IN_MS,
    sourceTokenId: account.idTokenClaims['uti'],
  }

  sessionStorage.setItem(
    `gmc.${account.idTokenClaims.aud}.${account.idTokenClaims.oid}`,
    JSON.stringify(newEntry)
  )
}

/**
 * Checks if the roles and permissions are in session storage and if their associated ID token is not expired
 * @param {AccountInfo} account
 * @returns {boolean}
 */
export const checkRolesAndPermissionsInStorage = account => {
  if (!account) return false

  const storageEntry = sessionStorage.getItem(
    `gmc.${account.idTokenClaims.aud}.${account.idTokenClaims.oid}`
  )

  if (!storageEntry) return false

  const parsedStorageEntry = JSON.parse(storageEntry)
  return (
    parsedStorageEntry.roles &&
    parsedStorageEntry.permissions &&
    parsedStorageEntry.expiresOn >= Date.now() &&
    parsedStorageEntry.sourceTokenId === account.idTokenClaims['uti']
  )
}

/**
 * Returns the roles array from session storage
 * @param {AccountInfo} account
 * @returns {Array}
 */
export const getRolesFromStorage = account => {
  if (!account) return null

  const storageEntry = sessionStorage.getItem(
    `gmc.${account.idTokenClaims.aud}.${account.idTokenClaims.oid}`
  )

  if (!storageEntry) return null

  return JSON.parse(storageEntry).roles
}

/**
 * Returns the permissions array from session storage
 * @param {AccountInfo} account
 * @returns {Array}
 */
export const getPermissionsFromStorage = account => {
  if (!account) return null

  const storageEntry = sessionStorage.getItem(
    `gmc.${account.idTokenClaims.aud}.${account.idTokenClaims.oid}`
  )

  if (!storageEntry) return null

  return JSON.parse(storageEntry).permissions
}

/**
 * This method clears session storage of roles and permissions claims for the given account.
 * @param {AccountInfo} account
 */
export const clearRolesAndPermissionsInStorage = account => {
  if (!account) return
  for (var key in sessionStorage) {
    if (
      key.startsWith(
        `gmc.${account.idTokenClaims.aud}.${account.idTokenClaims.oid}`
      )
    ) {
      sessionStorage.removeItem(key)
    }
  }
}
