import { createTheme, responsiveFontSizes } from '@mui/material/styles'

// Custom Typography
declare module '@mui/material/styles' {
  interface TypographyVariants {
    small: React.CSSProperties
    xsmall: React.CSSProperties
    title: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    xsmall?: React.CSSProperties
    small?: React.CSSProperties
    title?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    small: true
    xsmall: true
    title: true
  }
}

// theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#008F6B',
      light: '#00A476',
      dark: '#0C1C2A',
    },
    secondary: {
      main: '#21409A',
      light: '#F5F7FA',
      dark: '#798996',
    },
    error: {
      main: '#EF314E',
    },
    warning: {
      main: '#FBA300',
    },
    text: {
      primary: '#000000',
      secondary: '#798996',
    },
  },
  typography: {
    fontFamily: ['"Circular Std Book"', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
      fontSize: '3rem', // 48px
    },
    h2: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
      fontSize: '2.25rem', //36px
    },
    h3: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
      fontSize: '1.5rem', //24px
    },
    h4: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
      fontSize: '1.125rem', //18px
    },
    h5: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
      fontSize: '1rem', //16px
    },
    h6: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.875rem', // 14px
    },
    body1: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.875rem', // 14px
    },
    small: {
      fontFamily: ['"Circular Std Book"', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.75rem', // 12px
    },
    xsmall: {
      fontFamily: ['"Circular Std Book"', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.65rem',
    },
    title: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
      fontSize: '1rem', // 36px
    },
    subtitle1: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
      fontSize: '1rem', // 14px
    },
    caption: {
      fontFamily: ['"Circular Std"', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.75rem', // 12px
    },
    button: {
      fontFamily: ['"Circular Std Book"', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {},
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 7px 3px rgba(221, 229, 237, 0.5)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 7px 3px rgba(221, 229, 237, 0.5)',
        },
      },
    },
  },
})

export default responsiveFontSizes(theme)
