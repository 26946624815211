import { Box, CircularProgress, Typography } from '@mui/material'

const Loading = ({
  height = '100vh',
  message = undefined,
}: {
  height?: string
  message?: string | undefined
}): JSX.Element => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      height={height}
      display="flex"
      flexDirection="column"
    >
      <CircularProgress color="primary" />
      {message ? (
        <Typography
          data-testid="loading-message"
          variant="small"
          component="div"
          alignItems="center"
          sx={{
            mt: 1,
          }}
        >
          {message}
        </Typography>
      ) : null}
    </Box>
  )
}

export default Loading
